import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeedSection,
  SectionTitle,
  Posts,
  SiteHeader,
  SiteMain,
  SiteHeaderStyles,
} from '../styles/shared';
import config from '../config/website-config';
import { PageContext } from './post';

// import icon
import { FaRegStickyNote } from "@react-icons/all-files/fa/FaRegStickyNote";
import { FaPencilAlt } from "@react-icons/all-files/fa/FaPencilAlt";
import { FaRegStar } from "@react-icons/all-files/fa/FaRegStar";


export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    header: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    allPost: {
      edges: Array<{
        node: PageContext;
      }>;
    };
    lastPost: {
      edges: Array<{
        node: PageContext;
      }>;
    };
    blogPost: {
      edges: Array<{
        node: PageContext;
      }>;
    };
    getPost: {
      edges: Array<{
        node: PageContext;
      }>;
    };
    // allMarkdownRemark: {
    //   edges: Array<{
    //     node: PageContext;
    //   }>;
    // };
  };
}

const IndexPage: React.FC<IndexProps> = props => {
  const { width, height } = props.data.header.childImageSharp.fixed;

  // สำหรับตรวจสอบจำนวนเนื้อหาที่มี ถ้าไม่มีเลย (length == 0) จะแสดงเป็นข้อความอย่างอื่นแทน 
  const getPost = (content:any) => {
    let post = content;
    let checkPost = post.length;
    
    if (checkPost > 0) {
      return(
        postContent(post)
      )
    }else{
      return(<article><ComingSoon>ยังไม่มีเนื้อหาเลย ช่วยรอก่อนนะ...</ComingSoon></article>)
    }
  }

  const postContent = (value:any) => {
    let values = value;
    return(
      values.map((post:any, index:any) => {
        if (post.node.frontmatter.type === 'note') {
          return (
            (post.node.frontmatter.draft !== true || process.env.NODE_ENV !== 'production') && (
              <PostCard key={post.node.fields.slug} post={post.node} />
            )
          );
        }else{
          return (
            (post.node.frontmatter.draft !== true || process.env.NODE_ENV !== 'production') && (
              <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
            )
          );
        }
      })
    )
  }

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper>
        <div
          css={[outer, SiteHeader, SiteHeaderStyles]}
          className="site-header-background"
          // style={{
          //   backgroundImage: `url('${props.data.header.childImageSharp.fixed.src}')`,
          // }}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            {/* <SiteNav isHome /> */}
            {/* ส่วนของ title ในหน้าแรก */}
            {/* <SiteHeaderContent className="site-header-content">
              <SiteTitle className="site-title">
                {props.data.logo ? (
                  <img
                    style={{ maxHeight: '55px' }}
                    src={props.data.logo.childImageSharp.fixed.src}
                    alt={config.title}
                  />
                ) : (
                  config.title
                )}
              </SiteTitle>
              <SiteDescription>{config.description}</SiteDescription>
            </SiteHeaderContent> */}
          </div>
        </div>
        <main id="site-main" css={[SiteMain, outer]}>
          {/* == last post == */}
          <div css={[inner, Posts]}>
            <div css={[PostFeedSection]}>
              <h1 css={[SectionTitle]}><FaRegStar/>เขียนไว้ล่าสุด</h1>
              {getPost(props.data.lastPost.edges)}
              {/* {props.data.lastPost.edges.map((post, index) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
                  )
                );
              })} */}
            </div>
          </div>

          {/* == blog post == */}
          <div css={[inner, Posts]}>
            <div css={[PostFeedSection]}>
              <h1 css={[SectionTitle]}><FaPencilAlt/>นานาบทความ</h1>
              <br />
              {getPost(props.data.blogPost.edges)}

              {/* {props.data.blogPost.edges.map((post) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} />
                  )
                );
              })} */}
            </div>
          </div>

          {/* == note post == */}
          <div css={[inner, Posts]}>
            <div css={[PostFeedSection]}>
              <h1 css={[SectionTitle]}><FaRegStickyNote/>โน๊ตไปเรื่อย</h1>
              <br />
              {getPost(props.data.getPost.edges)}

              {/* {props.data.notePost.edges.map((post) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true || process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} />
                  )
                );
              })} */}
            </div>
          </div>
          
          {/* == default == */}
          {/* <div css={[inner, Posts]}>
            <div css={[PostFeed]}>
              {props.data.allPost.edges.map((post, index) => {
              // {props.data.allMarkdownRemark.edges.map((post, index) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
                  )
                );
              })}
            </div>
          </div> */}

        <br />
        <br />
        <br />
        </main>
        {props.children}
        {props.pageContext.numPages > 1 && (
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
          />
        )}
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    header: file(relativePath: { eq: "img/blog-cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 2000, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
    lastPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true }, type: {in: "blog"} } }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
    blogPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {draft: {ne: true}, type: {eq: "blog"}}}
      limit: 4
      skip: 1
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
    getPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {draft: {ne: true}, type: {eq: "note"}}}
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
  }
`;

// -- default query --
// export const pageQuery = graphql`
//   query blogPageQuery($skip: Int!, $limit: Int!) {
//     logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     header: file(relativePath: { eq: "img/blog-cover.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 2000, quality: 100) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     allMarkdownRemark(
//       sort: { fields: [frontmatter___date], order: DESC }
//       filter: { frontmatter: { draft: { ne: true } } }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           frontmatter {
//             title
//             date
//             tags
//             draft
//             excerpt
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 3720) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//             author {
//               id
//               bio
//               avatar {
//                 children {
//                   ... on ImageSharp {
//                     fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           excerpt
//           fields {
//             readingTime {
//               text
//             }
//             layout
//             slug
//           }
//         }
//       }
//     }
//   }
// `;

const ComingSoon = styled.p`
  padding: 0px 25px;
`;

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 2.4rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
